import {FunctionComponent} from 'react';
import styled from 'styled-components';
import Layout from '../layout';
import {Header} from '../layout/styled';

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const About: FunctionComponent = () => {
  return (
    <Layout>
      <Header>About Ryan</Header>
      <Description>
        <p>
          Hey, I'm Ryan Blakely, a software developer and photographer based in Philadelphia. I enjoy building apps that
          solve problems I encounter in my everyday life. Currently, I'm working at{' '}
          <a href="https://ring.com" target="_blank" rel="noopener noreferrer">
            Ring
          </a>{' '}
          on the web team.
        </p>
        <p>
          In my spare time, I enjoy taking pictures and reading photobooks. If I'm not thinking about software, I'm
          probably thinking about photography. I carry a camera everywhere I go.
        </p>
      </Description>
    </Layout>
  );
};

export default About;
