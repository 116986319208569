import {ProjectType} from '../pages/layout';

export const photographyImages = [
  'https://pub-2aa01b57ac11442cb3ddf2e76169a0fb.r2.dev/R0009937_small.jpeg',
  'https://pub-2aa01b57ac11442cb3ddf2e76169a0fb.r2.dev/rain.jpg',
];

export const projects: ProjectType[] = [
  {
    title: 'VR Eye Exercises',
    logoUrl: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1646862254/qdllerjnb9h8wok7ouju.svg',
    webUrl: 'https://www.vision-exercises.com',
    description: 'A web-based VR tool that allows users to create custom eye exercises to improve their vision.',
    background: '#f5f5ee',
    date: '2023 - Present',
  },
  {
    title: 'Garmin Watchface',
    logoUrl: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1677369532/u1xg9av9i17t6pofulrq.png',
    webUrl: 'https://apps.garmin.com/en-US/apps/e435b71f-a27f-4d54-a815-7b65611864b2',
    description: 'A dynamic watch face that updates data fields based on the time of day and weather.',
    background: '#1E2837',
    date: '2023 - Present',
  },
  {
    title: 'Magfinder',
    logoUrl: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1677369681/nr0rcmgybfyli63i9jjb.svg',
    webUrl: 'https://www.magfinder.com',
    description: 'A daily feed of independent magazines releases.',
    background: 'orange',
    date: '2022',
  },
  // {
  //     title: 'Garmin Watchface',
  //     icon: 'https://res.cloudinary.com/dccqw6mij/image/upload/v1661307866/fk1ig8lnrbymyizevjef.svg',
  //     url: 'https://www.youtube.com/watch?v=9qJFNoTSZfY',
  // },
];
