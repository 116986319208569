import {FunctionComponent} from 'react';

import {useLocation} from 'react-router-dom';
import {NavigationContainer, NavigationLeft, NavigationLink, NavigationRight, PageContainer, Title} from './styled';

export interface ProjectType {
  title: string;
  logoUrl: string;
  webUrl: string;
  description: string;
  date: string;
  background?: string;
}

const Layout: FunctionComponent<any> = ({children}) => {
  const location = useLocation();

  return (
    <PageContainer>
      <NavigationContainer>
        <NavigationLeft>
          <Title>Ryan Blakely</Title>
        </NavigationLeft>
        <NavigationRight>
          <NavigationLink to="/" active={location.pathname === '/'}>
            Software
          </NavigationLink>
          <NavigationLink to="/photography" active={location.pathname === '/photography'}>
            Photography
          </NavigationLink>
          <NavigationLink to="/about" active={location.pathname === '/about'}>
            About
          </NavigationLink>
        </NavigationRight>
      </NavigationContainer>
      {children}
    </PageContainer>
  );
};

export default Layout;
