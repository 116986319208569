import {FunctionComponent, useEffect, useState} from 'react';
import styled from 'styled-components';
import {photographyImages} from '../../database';
import Layout from '../layout';
import {Header} from '../layout/styled';

const Image = styled.img`
  width: 170px;
  height: auto;
  margin: 1em 0;

  &:hover {
    cursor: pointer;
  }
`;

const Modal = styled.div<{visible: boolean}>`
  display: ${({visible}) => (visible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 1001;

  &:hover {
    color: #ccc;
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: 1em;
`;

const RightArrow = styled(ArrowButton)`
  right: 1em;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1em;
`;

const Photography: FunctionComponent = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const openModal = (index: number) => {
    setModalImageIndex(index);
    setModalVisible(true);
  };

  const closeModal = () => setModalVisible(false);

  const showPreviousImage = () => {
    setModalImageIndex(prevIndex => (prevIndex === 0 ? photographyImages.length - 1 : prevIndex - 1));
  };

  const showNextImage = () => {
    setModalImageIndex(prevIndex => (prevIndex === photographyImages.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        showPreviousImage();
      } else if (event.key === 'ArrowRight') {
        showNextImage();
      } else if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalVisible) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalVisible]);

  return (
    <Layout>
      <Header>Photography</Header>
      <ImageGrid>
        {photographyImages.map((src, index) => (
          <Image key={index} src={src} alt={`Ryan's Photography ${index + 1}`} onClick={() => openModal(index)} />
        ))}
      </ImageGrid>
      <Modal visible={isModalVisible}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <LeftArrow onClick={showPreviousImage}>←</LeftArrow>
        <ModalImage src={photographyImages[modalImageIndex]} alt="Ryan's Photography" />
        <RightArrow onClick={showNextImage}>→</RightArrow>
      </Modal>
    </Layout>
  );
};

export default Photography;
