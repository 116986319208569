import {Route, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {UserProvider} from './hooks/useUser';
import About from './pages/about';
import Photography from './pages/photography';
import Software from './pages/software';
import {COLORS} from './static/colors';

function App() {
  let theme = {
    colors: COLORS,
    dark: true,
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Software />} index />
            <Route path="/photography" element={<Photography />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </UserProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
