import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 800px) {
    padding: 2em;
  }
`;

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3em;
  padding: 1em;

  @media (min-width: 800px) {
    gap: 6.5em;
    padding: 1em 2em;
  }
`;
export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 300;
  color: #838383;
`;

export const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 1em 0;
  margin-bottom: 4em;
  justify-content: space-between;
  font-size: 0.95em;
`;

export const NavigationLeft = styled.div`
  display: flex;
  flex-direction: row;
`;
export const NavigationRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

export const NavigationLink = styled(Link)<{active: boolean}>`
  color: ${({active}) => (active ? '#000' : '#838383')};
  text-decoration: none;

  &:hover {
    color: #838383;
  }
`;

export const Header = styled.h1`
  margin-bottom: 1.5em;
  font-weight: 400;
`;

export const Projects = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const Project = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: fit-content;
`;

export const ProjectTitle = styled(Link)`
  font-weight: 500;
  font-size: 0.95em;
`;

export const ProjectDescription = styled.p``;
export const ProjectTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;
export const ProjectDate = styled.p`
  color: #838383;
  font-size: 0.9em;
  align-content: center;
`;
