// src/pages/software.tsx
import {FunctionComponent} from 'react';
import {projects} from '../../database';
import Layout from '../layout';
import {
  Header,
  Project,
  ProjectDate,
  ProjectDescription,
  Projects,
  ProjectTitle,
  ProjectTitleContainer,
} from '../layout/styled';

export interface ProjectType {
  title: string;
  logoUrl: string;
  webUrl: string;
  description: string;
  date: string;
  background?: string;
}

const Software: FunctionComponent = () => {
  return (
    <Layout>
      <Header>Software</Header>
      <Projects>
        {projects &&
          projects.map((project: ProjectType) => (
            <Project key={project.title}>
              <ProjectTitleContainer>
                <ProjectTitle to={project.webUrl} target="_blank" rel="noopener noreferrer">
                  {project.title}
                </ProjectTitle>
                <ProjectDate>{project.date}</ProjectDate>
              </ProjectTitleContainer>
              <ProjectDescription>{project.description}</ProjectDescription>
            </Project>
          ))}
      </Projects>
    </Layout>
  );
};

export default Software;
